import React, { useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Home(props) {



    return(
        <>
           <Header 
           openModal = {props.openModal}
           name = "info"
           />
            <div className="home" align="center">
                    <p className="home--t1">Please connect Web3 wallet to claim XEN</p>
                    <button onClick={props.openModal} className="home--btn">Connect Wallet &nbsp; <i className="fa-solid fa-angle-down"></i></button>
            </div>



           <Footer />
        </>
    )
}

export default Home