import React from "react";

function Footer() {
    return(
        <>
            <div className="footer">
                <div className="w3-left footer--icons">
                    <i className="fa-brands fa-twitter footer--icon"></i>
                    <i className="fa-brands fa-youtube footer--icon"></i>
                    <a href="https://t.me/XEN_CryptoTalk" className="link"><i className="fa-brands fa-telegram footer--icon"></i></a>
                    <i className="fa-brands fa-github footer--icon"></i>
                    <i className="fa-brands fa-discord footer--icon"></i>
                    <i className="fa-brands fa-reddit-alien footer--icon"></i>

                    <i className="fa-regular fa-file footer--icon2"></i>
                </div>
                <div className="w3-right">
                    <p className="footer--t1">Contract: </p><br/>
                    <span className="footer--t2">Copyright © 2022-23 by 
                        <span className="footer--hover">Fair Crypto Foundation.</span>
                        All Rights Reserved. 
                        <span className="footer--hover">Terms Of Use</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Footer