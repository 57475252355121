import React from "react";
import Header from "../components/Header";
import Footer2 from "../components/Footer2";
import xenData from "../xenData";

function Explore(props) {
    return(
        <>
            <Header 
                name = {props.name}
                openModal={props.openModal}
            />
                <div className="explore">
                    <h1 className="explore--t1">Explore the XEN Universe</h1>
                    <div align="center" style={{"display": "flex", "gap": "12px", "justifyContent": "center"}}>
                        <button onClick={props.openModal} id="header--btn" className="header--btnV2">Connect Wallet</button>
                        <a href="https://app.popt.in/APIRequest/click/6944596b47291"><button id="header--btn" className="header--btnV2">Join Giveaway</button></a>
                    </div>
                    
                    <div className="explore--boxes">
                        {
                            xenData.map(data => {
                                return(
                                    <>
                                        <div onClick={props.openModal} className="explore--box">
                                            <img src={data.img}
                                            className="explore--box-lg"
                                            />
                                            <span className="explore--t2">{data.name}</span>
                                            <i className="fa-solid fa-circle explore--circle" style={{"color": `${data.color}`}}></i>
                                            <div>
                                                <p className="explore--t3a">Global Rank<span className="explore--t3b">{data.rank}</span></p>
                                                <p className="explore--t4a">Liquid Supply<span className="explore--t4b">{data.supply}</span></p>
                                            </div>
                                            <p className="explore--t5">{data.contract} &nbsp;<i className="fa-solid fa-up-right-from-square"></i></p>
                                        </div>  
                                    </>
                                )
                            })
                        } 
                    </div>
                </div>
            <Footer2 />
        </>
    )
}

export default Explore