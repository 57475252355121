export default [
    {
        id: "1",
        name: "Ethereum",
        img: "/images/ethereum-logo.png",
        rank: "8,027,134",
        supply: "4,831,843,367,037",
        contract: "0x06450D..",
        color: "green"
    },
    {
        id: "2",
        name: "BSC",
        img: "/images/bsc-logo.svg",
        rank: "14,318,858",
        supply: "8,129,117,328,791",
        contract: "0x2AB0E9..",
        color: "green"
    },
    {
        id: "3",
        name: "Polygon",
        img: "/images/polygon-logo.png",
        rank: "35,730,840",
        supply: "12,989,139,641,662",
        contract: "0x2AB0E9..",
        color: "rgb(237, 108, 2)"
    },
    {
        id: "4",
        name: "Avalanche C",
        img: "/images/avalanche-logo.png",
        rank: "791,119",
        supply: "126,032,652,265",
        contract: "0xC0C5AA..",
        color: "green"
    },
    {
        id: "5",
        name: "Ethereum PoW",
        img: "/images/ethpow-logo.png",
        rank: "35,316,027",
        supply: "15,881,706,575,992",
        contract: "0x2AB0E9..",
        color: "green"
    },
    {
        id: "6",
        name: "Moonbeam",
        img: "/images/moonbeam-logo.png",
        rank: "1,447,670",
        supply: "313,073,580,286",
        contract: "0xB564A5..",
        color: "green"
    },
    {
        id: "7",
        name: "Evmos",
        img: "/images/evmos-logo.png",
        rank: "4,424,993",
        supply: "429,804,388,352",
        contract: "0x2AB0E9..",
        color: "rgb(237, 108, 2)"
    },
    {
        id: "8",
        name: "Fantom",
        img: "/images/fantom-logo.svg",
        rank: "42,539,976",
        supply: "25,468,522,154,603",
        contract: "OxEF4B76..",
        color: "green"
    },
    {
        id: "9",
        name: "Dogechain",
        img: "/images/dogechain-logo.png",
        rank: "17,202,688",
        supply: "3,326,903,977,423",
        contract: "0x948EED..",
        color: "green"
    },
    {
        id: "10",
        name: "OKC (OKX Chain)",
        img: "/images/okx-logo.svg",
        rank: "100,795,364",
        supply: "39,300,112,308,800",
        contract: "0x1CC4D9..",
        color: "green"
    }
]