import React from "react";
import { Link } from "react-router-dom";

function Header(props) {

    const listHead = () => {
        if(props.name === "xen") {
            return(
                <>
                    <Link to="/" className="link"><p className="header--l active">XEN</p></Link>
                    <Link to="/mainnet" className="link"><p className="header--l">Info</p></Link>
                    <Link to="/mainnet/claim" className="link"><p className="header--l">Claim</p></Link>
                    <Link to="/mainnet/stake" className="link"><p className="header--l">Stake</p></Link>
                    <Link to="/" className="link"><p className="header--l">XENFT</p></Link>
                    <Link to="/" className="link"><p className="header--l">Charts</p></Link>
                    <Link to="/" className="link"><p className="header--l">Portfolio</p></Link>
                </>
            )
        } else if(props.name === "info") {
            return(
                <>
                    <Link to="/" className="link"><p className="header--l">XEN</p></Link>
                    <Link to="/mainnet" className="link"><p className="header--l active">Info</p></Link>
                    <Link to="/mainnet/claim" className="link"><p className="header--l">Claim</p></Link>
                    <Link to="/mainnet/stake" className="link"><p className="header--l">Stake</p></Link>
                    <Link to="/" className="link"><p className="header--l">XENFT</p></Link>
                    <Link to="/" className="link"><p className="header--l">Charts</p></Link>
                    <Link to="/" className="link"><p className="header--l">Portfolio</p></Link>
                </>
            )
        } else if(props.name === "mint") {
            return(
                <>
                    <Link to="/" className="link"><p className="header--l">XEN</p></Link>
                    <Link to="/mainnet" className="link"><p className="header--l">Info</p></Link>
                    <Link to="/mainnet/claim" className="link"><p className="header--l active">Claim</p></Link>
                    <Link to="/mainnet/stake" className="link"><p className="header--l">Stake</p></Link>
                    <Link to="/" className="link"><p className="header--l">XENFT</p></Link>
                    <Link to="/" className="link"><p className="header--l">Charts</p></Link>
                    <Link to="/" className="link"><p className="header--l">Portfolio</p></Link>
                </>
            )
        } else if (props.name === "stake") {
            return(
                <>
                    <Link to="/" className="link"><p className="header--l">XEN</p></Link>
                    <Link to="/mainnet" className="link"><p className="header--l">Info</p></Link>
                    <Link to="/mainnet/claim" className="link"><p className="header--l">Claim</p></Link>
                    <Link to="/mainnet/stake" className="link"><p className="header--l active">Stake</p></Link>
                    <Link to="/" className="link"><p className="header--l">XENFT</p></Link>
                    <Link to="/" className="link"><p className="header--l">Charts</p></Link>
                    <Link to="/" className="link"><p className="header--l">Portfolio</p></Link>
                </>
            )
        } else {
            return(
                <>
                    <Link to="/" className="link"><p className="header--l">XEN</p></Link>
                    <Link to="/mainnet" className="link"><p className="header--l">Info</p></Link>
                    <Link to="/mainnet/claim" className="link"><p className="header--l">Claim</p></Link>
                    <Link to="/mainnet/stake" className="link"><p className="header--l">Stake</p></Link>
                    <Link to="/" className="link"><p className="header--l">XENFT</p></Link>
                    <Link to="/" className="link"><p className="header--l">Charts</p></Link>
                    <Link to="/" className="link"><p className="header--l">Portfolio</p></Link>
                </>
            )
        }
    }


    const openNav = () => {
        document.querySelector("#header--list").style.display = "flex";
        document.querySelector("#header--nav").style.display = "none";
        document.querySelector("#header--btn").style.display = "none";
        document.querySelector("#header--nav2").style.display = "block";
    }

    const closeNav = () => {
        document.querySelector("#header--list").style.display = "none";
        document.querySelector("#header--nav").style.display = "block";
        document.querySelector("#header--btn").style.display = "block";
        document.querySelector("#header--nav2").style.display = "none";
    }

    
    return(
        <>
            <div className="header">
                <div className="w3-left header--list w3-animate-zoom" id="header--list">
                    {listHead()}
                </div>
                <i onClick={openNav} id="header--nav" className="fa-solid fa-bars header--nav w3-hide-large w3-hide-medium"></i>
                <i onClick={closeNav} id="header--nav2" className="fa-solid fa-xmark header--nav2 w3-hide-large w3-hide-medium"></i>
                <div className="w3-right header--right">
                    <i className="fa-solid fa-sun header--icon"></i>
                    <span className="header--t1">XEN: 0</span>
                    <span>
                        <select className="header--btn1">
                            <option>Ethereum</option>
                            <option>BSC</option>
                            <option>Polygon</option>
                            <option>Avalanche C</option>
                            <option>Ethereum PoW</option>
                            <option>Moonbeam</option>
                            <option>Evmos</option>
                            <option>Fantom</option>
                            <option>DogeChain</option>
                            <option>OKC (OKX Chain)</option>
                        </select>
                        <button onClick={props.openModal} id="header--btn" className="header--btn2">Connect Wallet &nbsp; <i className="fa-solid fa-angle-down"></i></button>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Header