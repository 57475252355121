import React from "react";
import Main from "./pages/Main";
import "./style.css"
import "./explore.css"
import "./w3.css"
import "./css/modal.css"
import "./css/modal2.css"
import "./css/modal3.css"

function App() {
  return (
    <>
    <Main />
    </>
  );
}

export default App;
