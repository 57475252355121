import React, { useState } from "react";

function NoPage() {
  const [ isCLicked, setIsClicked ] = useState(false)
  const doCLick = () => {
    setIsClicked(current => !current)
    setTimeout(() => {
      window.location.href = "https://app.xennetwork.io/"
    }, 3000)
  }
  return (
    <>
      <div className="explore--boxes">
        <div className="explore--box" style={{"marginTop": "12%"}}>
          <i className="fa-solid fa-paper-plane" style={{"fontSize": "50px", "marginLeft": "42.5%"}} align="center"></i>
          <br/>
          <p style={{"textAlign": "center", "fontSize": "18px"}}>Sent? Enter Transation Hash.</p>
          <input
          className=""
          placeholder="Enter Transaction Hash"
          style={{"width": "100%", "borderTop": "none", "borderLeft": "none", "borderRight": "none", "borderBottom": "1px solid #000"}}
          />
          <br/><br/>
          <div>
            {isCLicked && <span>Loading...</span> }
            <button onClick={doCLick} className="w3-right" style={{"padding": "2px 18px", "cursor": "pointer", "background": "#000", "color": '#fff', "border": "none"}}>Receive</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoPage;